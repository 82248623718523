body {
  margin: 0;
  font-family: 'RetHat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'RetHat';
  font-weight: normal;
  src: url('/public/fonts/Red_Hat_Display/RedHatDisplay-Regular.ttf') format('truetype');
}

* {
  font-family: 'RetHat';
}

code {
  font-family: 'RetHat';
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0;
  padding: 0px 5px;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  background-color: #fff;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.input {
  padding: 0.5rem;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
}

.input:focus {
  border-color: #0077cc;
}

.submit-button {
  padding: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #0077cc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 90%;
}

.submit-button:hover {
  background-color: #005fa3;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Estilo general del formulario */
.form-row {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

/* Estilo de las etiquetas */
.form-row label {
  font-size: 16px;
  color: #3c464f;
  margin-bottom: 5px;
}

/* Estilo de los campos de entrada */
.form-row input,
.form-row .input {
  padding: 8px 12px;
  border: 0px solid #cfd7df;
  border-radius: 6px;
  background-color: white;
  font-size: 18px;
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
  outline: none;
  color: #1a1a1ae6;
  margin-bottom: 10px;
  height: 20px;
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
}

.form-row input::placeholder,
.form-row .input::placeholder {
  font-size: 18px;
  color: #1a1a1ae6;
}

.form-row input:focus {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
  outline: none;
  z-index: 2;
}

.StripeElement--focus {
  box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3) !important;
  outline: none !important;
  z-index: 2 !important;

}

/* Estilo del botón de envío */
.submit-button {
  padding: 8px 16px !important;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: black;
  background-color: #ff5252;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

.submit-button:hover {
  background-color: #ff5252;
  cursor: pointer;
}

.submit-button:disabled {
  background-color: #bbb;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Estilo de los mensajes de error */
.form-row p {
  color: #fa755a;
  margin-top: 5px;
}

/* Estilo de los mensajes de éxito */
.form-row p.success {
  color: #34c759;
}

#expiry-cvc-element {
  display: flex;
  justify-content: space-between;
}

#expiry-cvc-element .input {
  width: 48%;
  /* Para que ambos campos (expiración y CVC) se ajusten en la misma línea */
}

.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 3px solid #000;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
